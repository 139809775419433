import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, 
    IonIcon, IonText, IonSelect, IonSelectOption } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
//import {InAppBrowser, InAppBrowserOptions} from '@ionic-native/in-app-browser';
import { Browser } from '@capacitor/browser';

const DojahKyc:React.FC<any> =  ({history,match}) => {
    const openKyc = async () => {
        await Browser.open({url:'https://verify.zypha.finance?userID='+match.params.email, windowName:'_self'});
        await Browser.addListener('browserFinished',()=>{
            alert('Please give us some time to review your kyc verification. If you have completed KYC try relogin or check back some few hours later');
            history.push('/login');
        });
        //const browser = InAppBrowser.create('https://verify.zypha.finance?userID=61762716221','_self');
    
    }
   return (
       <IonPage>
           <IonContent>
               <h1 style={{textAlign:'center'}}>Kyc</h1>
               <IonItem>
               <IonText color="primary" ><IonIcon icon={informationCircleOutline} />
                   Your ID is required for you to continue using our service.</IonText>
               </IonItem>
            <IonButton expand="full" onClick={()=>openKyc()}>Verify Your ID</IonButton>
                   <IonButton style={{marginTop:'2%'}} expand="full" fill="clear" href="/login">Login</IonButton>
              
               </IonContent>
           </IonPage>
   )
}

export default DojahKyc