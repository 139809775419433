import {
  IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, IonNote,
  IonIcon, IonText, IonList, IonListHeader, IonChip, useIonLoading, useIonToast,
  useIonViewWillEnter, IonSegment, IonSegmentButton, IonInfiniteScroll,
  IonInfiniteScrollContent, IonSpinner, useIonViewWillLeave, withIonLifeCycle, useIonViewDidEnter
} from '@ionic/react'
import React, { useContext, useState } from 'react';
import { Device } from '@capacitor/device';
import axios from 'axios';
import { GlobalContext } from '../state/GlobalState';
import Moment from 'react-moment';
import { Storage } from '@ionic/storage';

const Orders: React.FC<any> = () => {
  const { apiUrl, setTitle, isAuth } = useContext(GlobalContext);
  const [orders, setOrders] = useState<any>([]);
  const [orders1, setOrders1] = useState<any>([]);
  const [id, setID] = useState<any>();
  const [present, dismiss] = useIonLoading();
  const [presentT1, dismissT1] = useIonToast();
  const [segment, setSegment] = useState<any>('send_money');
  const [offset, setOffset] = useState<any>(0);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(true);
  const [disableSpinner, setDisableSpinner] = useState<any>(true);
  const [noTrx, setNoTrx] = useState(true);
  const [idToken_, setIDToken] = useState<any>();
  const loadOrders = async (evt: any, options: any) => {
    const _id = await Device.getId();
    setID(_id.uuid);
    setDisableSpinner(false);
    const _orders = await axios({
      url: apiUrl + '/getOrders',
      method: 'post',
      data: {
        id: _id.uuid,
        idToken: options != null ? options.idToken : idToken_,
        offset: offset
      },
      responseType: 'json'
    });
    if (_orders.data.error) {
      presentT1({
        buttons: [{ text: 'close', handler: () => dismissT1() }],
        message: _orders.data.msg,
        color: 'danger'
      });
    } else {
      setOrders1(_orders.data);
      filterOrders(segment, _orders.data);
      setOffset(offset > 0 ? offset + 25 : 25);

    }
    const _e = evt != null ? await evt.target.complete() : () => { };
    setDisableSpinner(true);

  }
  useIonViewWillEnter(async () => {
    setTitle('Orders');
    const storage = new Storage();
    await storage.create();
    const idtoken__ = await storage.get('idToken_');
    setIDToken(idtoken__);
  });
  useIonViewDidEnter(async () => {
    const storage = new Storage();
    await storage.create();
    const idtoken__ = await storage.get('idToken_');
    await loadOrders(null, { idToken: idtoken__ });
  });

  function handleSegmentChange(e: any) {
    setSegment(e);
    filterOrders(e, null);
  }

  function filterOrders(type: any, defaultArr: any) {
    const new_arr: any = [];
    let arrToUse;
    if (defaultArr != null) {
      arrToUse = defaultArr;
    } else {
      arrToUse = orders1;
    }
    arrToUse.map((order: any) => {
      if (order.type == type) {
        new_arr.push(order);
      }
    })
    setOrders(new_arr);
    if (new_arr.length <= 0) {
      setNoTrx(false);
    } else {
      setNoTrx(true);
    }
  }

  useIonViewWillLeave(async () => {
    setOrders([]);
    setOrders1([]);
    setSegment('send_money');
    setNoTrx(true);
    setDisableSpinner(true);
    setOffset(0);
  });
  return (
    <IonPage>
      <IonContent>

        <IonSegment style={{ padding: '1%' }} value={segment} onIonChange={(e) => handleSegmentChange(e.detail.value)} mode="ios">
          <IonSegmentButton value="send_money" >
            <IonLabel>Send Money</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="receive_money">
            <IonLabel>Receive Money</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="pay_bills">
            <IonLabel>Pay Bills</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonList>

          <div style={{ textAlign: 'center', display: noTrx == false ? 'block' : 'none' }}>
            <IonSpinner hidden={disableSpinner} />
          </div>
          <div style={{ textAlign: 'center', display: noTrx == false ? 'block' : 'none' }}>
            <IonText hidden={noTrx} color="medium" style={{ margin: 'auto' }} class="ion-text-center" >No orders under this section yet.</IonText>
            <br /><IonButton hidden={noTrx} routerLink="/categories">Start a transaction</IonButton>
          </div>
          {
            (orders.map((order: any) => (
              <IonItem detail={true} routerLink={'/orders/' + order.ref} >
                <IonLabel><IonText><b>#{order.ref}</b></IonText><br />
                  <IonText color="primary" >
                    {
                      order.type == "send_money" ? (order.customer != undefined ? order.customer.bank_name : '') :
                        (order.type == "receive_money" ? (order.bankDetails != undefined ? order.bankDetails.bankName : '') :
                          (order.type == "pay_bills" ? (order.biller != undefined ? order.biller.billername : '') : ''))
                    }
                  </IonText><br />
                  <IonText color="danger" >{order.amount}{order.currency.toUpperCase()}</IonText><br />
                  <IonNote><Moment fromNow={true}>{order.createdAt._seconds * 1000}</Moment></IonNote></IonLabel>
                <IonChip color={order.status == 'success' ? 'success' : (order.status == 'expired' ? 'danger' : (order.status == 'processing') ? 'dark' : (order.status == 'pending') ? 'medium' : 'danger')}>{order.status}</IonChip>
              </IonItem>
            )))
          }
        </IonList>
        <IonInfiniteScroll onIonInfinite={(e) => loadOrders(e, null)} disabled={isInfiniteDisabled}>
          <IonInfiniteScrollContent loadingText="Loading more orders..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  )
}

export default Orders;