import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, IonNote, IonIcon, IonText, useIonLoading, useIonToast, useIonViewWillEnter, withIonLifeCycle } from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons'
import React, { useContext, useState } from 'react';
import app from '../firebase.config.js';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { GlobalContext } from '../state/GlobalState';
import axios from 'axios';
const Recover: React.FC<any> = ({ history }) => {
    const [email, setEmail] = useState<any>('');
    const [present, dismiss] = useIonLoading();
    const [presentT1, dismissT1] = useIonToast();
    const { apiUrl, setTitle, isAuth } = useContext(GlobalContext);
    useIonViewWillEnter(async () => {
        setTitle('Recover');
    });
    const recover = async () => {
        try {
            await present('Please wait...');
            const _result = await axios({
                url: apiUrl + '/recoverUser',
                method: 'post',
                data: {
                    email: email,
                },
                responseType: 'json'
            });

            if (_result.data.error) {
                presentT1({
                    buttons: [{ text: 'close', handler: () => dismissT1() }],
                    message: _result.data.msg,
                    color: 'danger',
                    position: 'top'
                });
            } else {
                presentT1({
                    message: 'Recovery link has been sent to email!!!',
                    color: 'success',
                    duration: 5000,
                    position: 'top'
                });
            }
        } catch (e: any) {
            alert(e.message);
        } finally {
            dismiss();
        }
    }
    return (
        <IonPage>
            <IonContent>
                <h1 style={{ textAlign: 'center' }}>Recover Password</h1>
                <IonItem>
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonInput placeholder="Email" autofocus={true} clearInput={true}
                        type="email" value={email} onIonChange={(e) => setEmail(e.detail.value)} />
                </IonItem>
                <IonButton expand="full" onClick={() => recover()}>Send Recovery Email</IonButton>
                <IonButton style={{ marginTop: '2%' }} expand="full" fill="clear" onClick={(e) => history.push('/login')}>Login</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default withIonLifeCycle(Recover)