import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, IonNote, IonIcon, IonText, IonSelect, IonSelectOption, IonCheckbox, IonImg, IonChip, IonCol, IonGrid, IonRow } from '@ionic/react'
import { copyOutline, informationCircleOutline } from 'ionicons/icons'
import React from 'react'
import BonusBalance from '../components/BonusBalance'

const Referrals:React.FC<any> = () => {
    return (
        <IonPage>
            <IonContent>
               <BonusBalance balance={1034}></BonusBalance>
                <IonGrid>
                    <IonRow>
                        <IonCol><b>Username</b></IonCol>
                        <IonCol><b>Earned</b></IonCol>
                        </IonRow>
                    <IonRow>
                        <IonCol>Anasiudu Ikechukwu</IonCol>
                        <IonCol>#20.0</IonCol>
                        </IonRow>
                    </IonGrid>
                <IonButton expand="block" href="/referrals/bonus">Bonuses Earned</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default Referrals