import { IonPage,  IonContent, IonCol, IonGrid, IonRow, useIonViewWillEnter, IonText, useIonViewDidEnter, IonSpinner, useIonViewDidLeave } from '@ionic/react'
import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import Moment from 'react-moment';
import app from '../firebase.config';
import { GlobalContext } from '../state/GlobalState';

const Referrals:React.FC<any> = () => {
    const {apiUrl, setTitle, idToken_} = useContext(GlobalContext);
    const [bonus, setBonus] = useState([]);
    const [noTrx, setNoTrx] = useState(true);
    const [disableSpinner, setDisableSpinner] = useState<any>(true); 
useIonViewDidEnter(async () =>  {
    setTitle('Bonus Earnings');
    setDisableSpinner(false);
    const _bonus = await axios({
        url:apiUrl+'/getBonus',
        method: 'post',
        data: { 
            idToken: idToken_
        },
    responseType: 'json'});
    setBonus(_bonus.data);
    if(_bonus.data.length <= 0){
        setNoTrx(true);
    }
    setDisableSpinner(true);
});
useIonViewDidLeave(()=>{
    setBonus([]);
})
    return (
        <IonPage> 
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol><b>Amount</b></IonCol>
                        <IonCol><b>Earned</b></IonCol>
                        <IonCol><b>Date</b></IonCol>
                        </IonRow>
                        
           <div style={{textAlign:'center'}}>
                 <IonSpinner hidden={disableSpinner} />
            </div>
        <IonText hidden={ noTrx} color="medium">You have not earned any bonus, share your referral code to start earning now </IonText>
          {
            (bonus.map((b:any) => (
                    <IonRow>
                        <IonCol>{b.billAmount}</IonCol>
                        <IonCol>{b.amount}</IonCol>
                        <IonCol><Moment format="D/M/YYYY h:mm a">{b.createdAt._seconds * 1000}</Moment></IonCol>
                        </IonRow>
            ))) }
                    </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Referrals