import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, IonNote, useIonLoading, useIonToast } from '@ionic/react'
import React, { useContext, useState } from 'react'
import { Storage } from '@ionic/storage';
import axios from "axios";
import { GlobalContext } from '../state/GlobalState';

const KycVerify:React.FC<any> = ({history}) => {
    
    const {apiUrl} = useContext(GlobalContext);
    const [firstname, setFirstName] = useState<any>();
    const [middlename, setMiddleName] = useState<any>();
    const [lastname, setLastName] = useState<any>();
    const [dob, setDOB] = useState<any>();
    const [phoneNumber, setPhoneNumber] = useState<any>();
    const [present, dismiss] = useIonLoading();
    const [presentT1, dismissT1] = useIonToast();
    const verify = async()=>{
        present({
            message: 'Please wait'
          });
        const storage = new Storage();
        storage.create();
        const kyc = await storage.get('kyc');
        const result = await axios({
            url: apiUrl+'/verifyKyc',
            method: 'post',
            responseType: 'json',
            data: {
                firstname: firstname,
                middlename: middlename,
                lastname: lastname,
                dob: dob,
                phoneNumber: phoneNumber,
                kyc: kyc
            }
        });
        console.log(result);
        if(result.data.error){
            presentT1({ 
                buttons: [{ text: 'hide', handler: () => dismissT1() }],
                message: result.data.msg,
                color:'danger'
            });
        }else{
            await storage.set('kyc_meta', { ...result.data.data, userPhoneNumber: phoneNumber});
            history.push('/join');
        }
        
        dismiss();
    }
    return (
        <IonPage>
            <IonContent>
                <h1 style={{textAlign:'center'}}>Verify Kyc Information</h1>
                <IonNote color="primary">Please fill below boxes according to how it
                 appears on your registered ID profile</IonNote>
            <IonItem>
                <IonLabel position="stacked">First Name</IonLabel>
                <IonInput placeholder="Firstname" autofocus={true} clearInput={true}
                value={firstname} onIonChange={(e)=>setFirstName(e.detail.value)}/>
                </IonItem>
                <IonItem>
                <IonLabel position="stacked">Middle Name</IonLabel>
                <IonInput placeholder="MiddleName" clearInput={true} 
                value={middlename} onIonChange={(e)=>setMiddleName(e.detail.value)}/>
                </IonItem>
                <IonItem>
                <IonLabel position="stacked">Last Name</IonLabel>
                <IonInput placeholder="LastName" clearInput={true} 
                value={lastname} onIonChange={(e)=>setLastName(e.detail.value)}/>
                </IonItem>
                <IonItem>
                <IonLabel position="stacked">Date Of Birth</IonLabel>
                <IonInput placeholder="Date Of Birth" clearInput={true} type="date"
                value={dob} onIonChange={(e)=>setDOB(e.detail.value)}/>
                </IonItem>
                <IonItem>
                <IonLabel position="stacked">Phone Number</IonLabel>
                <IonInput placeholder="Phone Number" clearInput={true} 
                value={phoneNumber} onIonChange={(e)=>setPhoneNumber(e.detail.value)} />
                </IonItem>
                    <IonButton expand="full" onClick={()=>verify()}>Verify</IonButton>
                </IonContent>
            </IonPage>
    )
}

export default KycVerify