import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, 
     IonIcon, IonText, IonSelect, IonSelectOption } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { Storage } from '@ionic/storage';

const Kyc:React.FC<any> = ({history}) => {
    const [id_type, setIDType] = useState<any>();
    const [id_number, setIDNumber] = useState<any>();
    const kyc = async() => {
        const storage = new Storage();
        await storage.create();
        await storage.set('kyc',{
            id_type: id_type,
            id_number: id_number
        });
        history.push('/kyc/verify');
    }
    return (
        <IonPage>
            <IonContent>
                <h1 style={{textAlign:'center'}}>Kyc</h1>
                <IonItem>
                <IonText color="primary" ><IonIcon icon={informationCircleOutline} />
                    Your ID is required to verify your details.</IonText>
                </IonItem>
            <IonItem>
                <IonLabel position="stacked">Type Of ID</IonLabel>
                <IonSelect value={id_type} onIonChange={(e) => setIDType(e.detail.value)}>
                    <IonSelectOption value="national_identity">
                        National Identity</IonSelectOption>
                    <IonSelectOption value="drivers_license">
                        Drivers License</IonSelectOption>
                    <IonSelectOption value="voters_card">
                        Voters Card</IonSelectOption>
                    <IonSelectOption value="bvn">
                        Bvn</IonSelectOption>
                </IonSelect>
                </IonItem>
            <IonItem>
                <IonLabel position="stacked">ID Number</IonLabel>
                <IonInput placeholder="ID Number" autofocus={true} clearInput={true} value={id_number} onIonChange={(e)=>setIDNumber(e.detail.value)}/>
                  </IonItem>  <IonButton expand="full" onClick={()=>kyc()}>Next</IonButton>
                    <IonButton style={{marginTop:'2%'}} expand="full" fill="clear" href="/login">Login</IonButton>
               
                </IonContent>
            </IonPage>
    )
}

export default Kyc