import axios from "axios";
import { Clipboard } from '@capacitor/clipboard';
import { useState } from "react";
import { IonContent,IonIcon,IonImg,IonItem,IonNote,IonPage,IonText,useIonViewWillEnter,
      useIonToast  } from '@ionic/react';
import { copyOutline } from "ionicons/icons";
const ViewOrderContainer: React.FC<any> = ({history,match}) => {
const apiUrl = 'https://us-central1-zypha-40180.cloudfunctions.net';
const [order, setOrder] = useState<any>({coin:'',currency:''});
const [qrLink, setQrLink] = useState<any>();
const [present, dismiss] = useIonToast();
    const loadOrder = async() => {
        console.log(match);
        const _order = await axios({
            url:apiUrl+'/getOrder',
            method: 'post',
            data: { 
                ref: match.params.ref
            },
            responseType: 'json'});
        setOrder(_order.data);
        setQrLink('https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl='+_order.data.addressIn);
    }
    const copyAddr = async() =>{
        await Clipboard.write({
            string: order.addressIn
          });
          present({
            buttons: [{ text: 'hide', handler: () => dismiss() }],
            message: "Address copied",
            onDidDismiss: () => console.log('dismissed'),
            onWillDismiss: () => console.log('will dismiss'),
          });
    }
    const copyID = async() =>{
        await Clipboard.write({
            string: order.ref
          });
          present({
            buttons: [{ text: 'hide', handler: () => dismiss() }],
            message: "ID copied",
            onDidDismiss: () => console.log('dismissed'),
            onWillDismiss: () => console.log('will dismiss'),
          });
    }
useIonViewWillEnter(async () =>  {
    console.log('ionViewWillEnter event fired');
    await loadOrder();
  })
  return (
      <IonPage>
          <IonContent >
          
              <IonItem>
              <IonText  style={{margin:'auto', fontWeight:'200'}} onClick={()=>copyID()}
        class="ion-text-center">{order.ref}<IonIcon icon={copyOutline} onClick={()=>copyID()} /></IonText>
              </IonItem>
              <IonItem >
        <IonImg src={qrLink} style={{width:'200px', height:'200px', margin: 'auto'}}/></IonItem>
        <IonItem >
            <IonText style={{margin:'auto', fontWeight:'200'}} onClick={()=>copyAddr()}
        class="ion-text-center">{order.addressIn}</IonText><IonIcon icon={copyOutline} onClick={()=>copyAddr()} />
        </IonItem>
        <IonItem >
            <IonText style={{margin:'auto'}} 
        class="ion-text-center">Send {order.amount}{order.currency.toUpperCase()} ~ {order.amountToSend}{order.coin.toUpperCase()} to the above address</IonText>
        </IonItem>
        <IonItem >
            <IonNote style={{margin:'auto'}} 
        class="ion-text-center" color="warning">This payment address will expire after 1 hour. Send exact amount to above address.</IonNote>
        </IonItem>
        <IonItem >
            <IonNote style={{margin:'auto'}} 
        class="ion-text-center" color="secondary">Fiat equivalence may differ due to exchange rate and price fluctuations.</IonNote>
        </IonItem>
    </IonContent>
    </IonPage>
  );
};

export default ViewOrderContainer;
