import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, useIonLoading, useIonToast, useIonViewWillEnter, withIonLifeCycle } from '@ionic/react'
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../state/GlobalState';
import app from '../firebase.config.js';
import axios from 'axios';
import { getAuth, signInWithEmailAndPassword, sendEmailVerification, onAuthStateChanged, getIdToken, indexedDBLocalPersistence, } from "firebase/auth";
import { getMessaging, getToken, onMessage, } from "firebase/messaging";
import { Storage } from '@ionic/storage';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Toast } from "@capacitor/toast";
import { LocalNotifications, ScheduleOptions, LocalNotificationSchema, LocalNotificationDescriptor, ScheduleResult } from "@capacitor/local-notifications";

const Login: React.FC<any> = ({ history }) => {
    const [email, setEmail] = useState<any>('');
    const [password, setPassword] = useState<any>('');
    const [present, dismiss] = useIonLoading();
    const [presentT1, dismissT1] = useIonToast();
    const { apiUrl, setAuth, setVerified, setIDToken_, setTitle } = useContext(GlobalContext);
    const nullEntry: any[] = []
    //const [notifications, setnotifications] = useState(nullEntry);
    const [fcmToken, setFcmToken] = useState<any>();
    useIonViewWillEnter(async () => {
        setTitle('Login');
        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
                PushNotifications.requestPermissions().then((res) => {
                    if (res.receive === 'denied') {
                        //showToast('Push Notification permission denied');
                    }
                    else {
                        //showToast('Push Notification permission granted');
                        register();
                    }
                });
            }
            else {
                register();
            }
        }).catch((err) => {
            console.log(err);
            console.log(Notification.permission);
            if (Notification.permission === 'granted') {
                const messaging = getMessaging(app);
                //console.log(messaging);
                getToken(messaging, { vapidKey: 'BLYXto9wO2ctozyE_EAjN7YpO9V86t-NRNEGObjEUEwDf-TcOebfcmNZdgcobPGp4N00VLZHw2S9l46qJaE30lQ' }).then((currentToken) => {
                    if (currentToken) {
                        // Send the token to your server and update the UI if necessary
                        // ...
                        //console.log(currentToken);
                        setFcmToken(currentToken);
                        //showToast('Notification registration success');
                    } else {
                        // Show permission request UI
                        console.log('No registration token available. Request permission to generate one.');
                        // ...
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    // ...
                });
            } else {
                console.log('Requesting permission...');
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        // showToast('Notification permission granted.');
                    } else {
                        console.log('Permission request failed');
                    }
                });
            }
        });
    });

    const getUser = async (idtoken_: any) => {

        const _user = await axios({
            url: apiUrl + '/getUser',
            method: 'post',
            data: {
                idToken: idtoken_
            },
            responseType: 'json'
        });
        return { ..._user.data };
    }
    const login = async () => {

        await present({
            message: 'Please wait'
        });
        try {

            const _result = await axios({
                url: apiUrl + '/loginUser',
                method: 'post',
                data: {
                    email: email,
                    password: password,
                    fcmToken: fcmToken
                },
                responseType: 'json'
            });

            if (_result.data.error) {
                presentT1({
                    buttons: [{ text: 'close', handler: () => dismissT1() }],
                    message: _result.data.msg,
                    color: 'danger',
                    position: 'top'
                });
            } else {
                const user = await getUser(_result.data.idToken);
                if (!user.kyc.isVerified) {
                    history.push('/dojah/' + email);
                } else {
                    setIDToken_(_result.data.idToken);

                    //lets try storage
                    const storage = new Storage();
                    await storage.create()
                    await storage.set('idToken_', _result.data.idToken);
                    setAuth(true);

                    setVerified(true);

                    presentT1({
                        message: 'Signed in successfully!!!',
                        color: 'success',
                        duration: 5000,
                        position: 'top'
                    });
                    history.push('/categories');


                }
            }
        } catch (e: any) {
            presentT1({
                buttons: [{ text: 'close', handler: () => dismissT1() }],
                message: e.message,
                color: 'danger',
                position: 'top'
            });
        } finally {
            dismiss();
        }
    }
    const register = () => {
        console.log('Initializing HomePage');

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        //alert('Registered');
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                //alert('Token'+token.value);
                //showToast('Push registration success');
                setFcmToken(token.value);
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                //alert('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
                /*const _notifications: LocalNotificationSchema[] = [];
                _notifications.push({
                    title: notification.title != undefined ? notification.title : '',
                    body: notification.body != undefined ? notification.body : '',
                    id: Number.parseInt(notification.id) != undefined ? Number.parseInt(notification.id) : 0,
                    extra: notification.data
                });
                const _scheduleOptions: ScheduleOptions = {
                    notifications: _notifications
                }
                LocalNotifications.schedule(_scheduleOptions);*/
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (_notification: ActionPerformed) => {
                // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    }

    const showToast = async (msg: string) => {
        await Toast.show({
            text: msg
        })
    }
    return (
        <IonPage>
            <IonContent>
                <h1 style={{ textAlign: 'center' }}>Log In</h1>
                <IonItem>
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonInput color="dark" placeholder="Email" autofocus={true} clearInput={true} type="email" value={email} onIonChange={(e) => setEmail(e.detail.value)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Password</IonLabel>
                    <IonInput placeholder="Password" clearInput={true} type="password" value={password} onIonChange={(e) => setPassword(e.detail.value)} />
                </IonItem>
                <IonItem>
                    <a onClick={(_e) => history.push('/recover')}>Recover Password</a>
                </IonItem>
                <IonButton expand="full" onClick={() => login()}>Log In</IonButton>
                <IonButton style={{ marginTop: '2%' }} expand="full" fill="clear" onClick={(_e) => history.push('/join')}>Create Account</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default Login;