import axios from "axios";
import { useState } from "react";
import { IonContent,IonIcon,IonImg,IonItem,IonNote,IonPage,IonText,useIonViewWillEnter,
      useIonToast,
      IonButton,
      IonInput,
      IonLabel,
      useIonLoading} from '@ionic/react';
import { copyOutline } from "ionicons/icons";
const RefundOrderContainer: React.FC<any> = ({history,match}) => {
const apiUrl = 'https://us-central1-zypha-40180.cloudfunctions.net';
const [order, setOrder] = useState<any>({coin:'',currency:''});
const [address, setAddress] = useState<any>('');
const [present, dismiss] = useIonToast();
const [present1, dismiss1] = useIonLoading();
const loadOrder = async() => {
    console.log(match);
    const _order = await axios({
        url:apiUrl+'/getOrder',
        method: 'post',
        data: { 
            ref: match.params.ref
        },
        responseType: 'json'});
    setOrder(_order.data);
 }
const refund = async() => {
    present1({
        message: 'Please wait'
      });
    const response = await axios({
        url:apiUrl+'/refund',
        method: 'post',
        data: { 
            ref: order.ref,
            address: address
        },
        responseType: 'json'});
        if(response.data.error){
            present({
                buttons: [{ text: 'hide', handler: () => dismiss() }],
                color:'danger',
                message: response.data.msg,
                onDidDismiss: () => {console.log('dismissed')},
                onWillDismiss: () => console.log('will dismiss'),
              });
        }else{
            present({
                buttons: [{ text: 'hide', handler: () => dismiss() }],
                message: "Refund request sent",
                onDidDismiss: () => {console.log('dismissed')},
                onWillDismiss: () => console.log('will dismiss'),
              });
              history.replace('/tab2');
        }
        
        dismiss1();
}
useIonViewWillEnter(async () =>  {
    console.log('ionViewWillEnter event fired');
    await loadOrder();
  })
  return (
      <IonPage>
          <IonContent >
              <IonItem>
              <IonText  style={{margin:'auto', fontWeight:'200'}} 
        class="ion-text-center">Refund request for {order.ref}</IonText>
              </IonItem><IonItem>
              <IonLabel position="stacked">Your {order.coin.toUpperCase()} Address</IonLabel>
              <IonInput onIonChange={e => setAddress(e.detail.value)} value={address}>
                  </IonInput></IonItem>
              <IonButton expand="full" color="success" onClick={(e) => refund()}>Refund</IonButton>
         
        <IonItem >
        </IonItem>
    </IonContent>
    </IonPage>
  );
};

export default RefundOrderContainer;
