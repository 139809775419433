import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, IonNote, IonIcon, IonText, IonSelect, IonSelectOption, IonCheckbox, useIonLoading, useIonToast, useIonViewWillEnter, IonToggle } from '@ionic/react'
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../state/GlobalState';
import { Storage } from '@ionic/storage';
import { Device } from '@capacitor/device';
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';


const NewOrder: React.FC<any> = ({ history, match }) => {
  const { apiUrl, setTitle, isAuth, idToken_ } = useContext(GlobalContext);
  const [coin, setCoin] = useState<any>();
  const [coins, setCoins] = useState<any>([]);
  const [id, setID] = useState<any>('');
  const [device, setDevice] = useState<any>();
  const [present, dismiss] = useIonToast();
  const [presentL1, dismissL1] = useIonLoading();
  const [email, setEmail] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [items, setItems] = useState<any>([]);
  const [item, setItem] = useState<any>({ paymentitemname: '', isAmountFixed: false });
  const [amount, setAmount] = useState<any>();
  const [customer, setCustomer] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [biller, setBiller] = useState<any>({ billername: '', customerfield1: '', customerfield2: '' });
  const [applyBonus, setApplyBonus] = useState<any>(false);
  const [idToken, setIDToken] = useState<any>();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [validationFeedback, setValidationFeedback] = useState<any>({});
  const fetchCoins = async () => {
    const _coins = await axios.get(apiUrl + '/getCoins');
    setCoins(_coins.data);
    setCoin(_coins.data[0]);
  }
  const fetchBiller = async () => {
    /*presentL1({
      message: 'Please wait'
    })*/
    const _items = await axios({
      url: apiUrl + '/getSVABiller',
      method: "post",
      responseType: 'json',
      data: {
        biller_id: match.params.billerid
      }
    });
    if (_items.data.paymentitems !== undefined) {
      setItems(_items.data.paymentitems);
      setItem(_items.data.paymentitems[0]);
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
      present({
        buttons: [{ text: 'close', handler: () => dismiss() }],
        message: 'Error processing request ',
        color: 'danger',
        position: 'top'
      });
    }
    //dismissL1();
  }

  const fetchSVA = async () => {

    const storage = new Storage();
    await storage.create();
    const _ca = await storage.get('category');
    const _bi = await storage.get('biller');
    setCategory(_ca);
    setBiller(_bi);
  }
  useIonViewWillEnter(async () => {
    setTitle('New Order');
    fetchBiller();
    fetchCoins();
    fetchSVA();
    const _id = await Device.getId();
    const _device = await Device.getInfo();
    setDevice(_device);
    setID(_id.uuid);
  })

  const handleProduct = (value: any) => {
    const KOBO_UNIT = 100;
    setItem(value);
    setAmount(value.amount / KOBO_UNIT);
  }
  const validateOrder = async () => {
    //const smc = coin.split(' ');
    //const _1 = smc[1];
    //const _0 = smc[0];
    const trx = await axios({
      url: apiUrl + '/SVAValidateBillPayment',
      method: 'post',
      responseType: 'json',
      data: {
        amount: amount,
        item: item,
        customer: customer
      }
    });
    return trx;
  }


  const createOrder = async (feedback: any) => {
    // const smc = coin.split(' ');
    // const _1 = smc[1];
    // const _0 = smc[0];
    const trx = await axios({
      url: apiUrl + '/sendSVABillPaymentX',
      method: 'post',
      responseType: 'json',
      data: {
        id: id,
        device: device,
        amount: amount,
        item: item,
        customer: customer,
        category: category,
        biller: biller,
        applyBonus: applyBonus,
        idToken: idToken_,
        coin: coin,
        //smc: _1,
        validationFeedback: feedback,
        email: email,
        phoneNumber: phoneNumber
      }
    });
    return trx;
  }

  const sendBillPayment = async () => {

    await presentL1({
      message: 'Please wait'
    });
    if (item == null || amount == null || amount == 0 || customer == null) {
      present({
        buttons: [{ text: 'close', handler: () => dismiss() }],
        message: 'All values are required. Check missing values',
        color: 'danger',
        position: 'top'
      });
    } else {
      const trx = await validateOrder();
      const customers = trx.data['Customers'];
      if (customers == undefined) {
        present({
          buttons: [{ text: 'close', handler: () => dismiss() }],
          message: "Validation failed",
          color: 'danger',
          position: 'top'
        });
      } else {
        if (customers[0].responseCode.charAt(0) !== '9') {
          present({
            buttons: [{ text: 'close', handler: () => dismiss() }],
            message: "Error processing request: " + customers[0].responseDescription,
            color: 'danger',
            position: 'top'
          });
        } else {

          const { value } = await Dialog.confirm({
            title: 'Confirm',
            message: `Validation was successful for ${customers[0].fullName}. Do you wish to proceed? `,
          });
          if (value) {
            const trx2 = await createOrder(trx.data);
            if (trx2.data.error == true) {
              present({
                buttons: [{ text: 'close', handler: () => dismiss() }],
                message: trx2.data.msg,
                color: 'danger',
                position: 'top'
              });
            } else {
              if (trx2.data.amountToSend == 0) {
                present({
                  buttons: [{ text: 'close', handler: () => dismiss() }],
                  message: "Your bonus balance was applied to transaction request. Please give time to fulfill",
                  color: 'success',
                  position: 'top'
                });
              } else {
                history.push('/pay/' + trx2.data.ref);
              }
            }
          }
        }
      }
    }
    dismissL1();
  }
  return (
    <IonPage>
      <IonContent>
        <h3 style={{ textAlign: 'center' }}>{biller.billername}</h3>
        <IonItem>
          <IonLabel position="stacked">{biller.customerfield1}</IonLabel>
          <IonInput placeholder={biller.customerfield1} autofocus={true}
            clearInput={true} value={customer} onIonChange={(e) => setCustomer(e.detail.value)} />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Select an option</IonLabel>
          <IonSelect okText="Okay" cancelText="Dismiss" value={item}
            onIonChange={e => handleProduct(e.detail.value)} >
            {
              items.map((item: any) =>
              (
                <IonSelectOption value={item}>{item.paymentitemname}</IonSelectOption>
              )
              )
            }
          </IonSelect>
        </IonItem><IonItem>
          <IonLabel position="stacked">Select coin</IonLabel>
          <IonSelect value={coin} okText="Okay" cancelText="Dismiss"
            onIonChange={e => setCoin(e.detail.value)}>
            {
              coins.map((coin: any) =>
              (
                <IonSelectOption value={coin}>{coin.name}</IonSelectOption>
              )
              )
            }
          </IonSelect>
        </IonItem>
        <IonItem >
          <IonLabel position="stacked">Amount</IonLabel>
          <IonInput readonly={item.isAmountFixed} value={amount}
            onIonChange={e => setAmount(e.detail.value)} />
        </IonItem>
        <IonItem >
          <IonLabel position="stacked">Email</IonLabel>
          <IonInput value={email}
            onIonChange={e => setEmail(e.detail.value)} />
        </IonItem>
        <IonItem >
          <IonLabel position="stacked">Phone Number</IonLabel>
          <IonInput value={phoneNumber}
            onIonChange={e => setPhoneNumber(e.detail.value)} />
        </IonItem>
        <IonItem hidden={!isAuth}>
          <IonLabel>Apply my bonus balance as discount</IonLabel>
          <IonToggle checked={applyBonus} onIonChange={(e) => setApplyBonus(e.detail.checked)} />
        </IonItem>
        <IonButton expand="full" onClick={() => sendBillPayment()} disabled={btnDisabled}>Continue</IonButton>
      </IonContent>
    </IonPage>
  )
}

export default NewOrder