import {
    IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, IonNote,
    IonIcon, IonText, IonSelect, IonSelectOption, IonCheckbox, IonImg, IonChip,
    IonCol, IonGrid, IonRow, useIonToast, useIonViewWillEnter, IonSkeletonText,
    useIonViewDidLeave, useIonLoading, withIonLifeCycle
} from '@ionic/react'
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../state/GlobalState';
import { Clipboard } from '@capacitor/clipboard';
import Moment from 'react-moment';
import { copyOutline } from 'ionicons/icons';
import { matchPath } from 'react-router';

const ViewOrder: React.FC<any> = ({ history, match }) => {
    const { apiUrl, setTitle } = useContext(GlobalContext);
    const [order, setOrder] = useState<any>({
        coin: '', currency: '', createdAt: {},
        validationFeedback: { Customers: [{ fullName: '' }] },
        customer: { account_name: '', account_number: '', bank_name: '' },
        bankDetails: { accountNumber: '', accountName: '', bankName: '' },
        biller: { billername: '' }, rates: { usd: 0, meta: { ngn: 0 } }
    });
    const [disableSKText, setDisableSKText] = useState(false);
    const [presentL1, dismissL1] = useIonLoading();

    const [present, dismiss] = useIonToast();
    const loadOrder = async () => {

        const match2 = matchPath(history.location.pathname, {
            path: match.path,
            exact: match.isExact,
            strict: false
        });
        const params: any = match2?.params;
        const _order = await axios({
            url: apiUrl + '/getOrder',
            method: 'post',
            data: {
                ref: params.orderid
            },
            responseType: 'json'
        });
        setOrder(_order.data);
        setDisableSKText(true);
    }

    const copyID = async () => {
        await Clipboard.write({
            string: order.ref
        });
        present({
            buttons: [{ text: 'hide', handler: () => dismiss() }],
            message: "ID copied"
        });
    }
    useIonViewWillEnter(async () => {
        console.log([history, match]);
        setTitle('View Order');
        await loadOrder();
    });

    const retryOrder = async () => {
        /*presentL1({
            message: 'Please wait'
          });
       await axios({
            url:apiUrl+'/getOrder',
            method: 'post',
            data: { 
                ref: match.params.orderid
            },
        responseType: 'json'});
        window.location.reload();*/
    }
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol><b>ID</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated /><IonText onClick={() => copyID()}>#{order.ref}<IonIcon icon={copyOutline} onClick={() => copyID()} /></IonText></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Coin</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.coin} </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Coin Amount</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.amountToSend} </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Amount</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.amount}{order.currency}</IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol><b>Rate</b></IonCol>
                        <IonCol> {order.rates.meta.ngn}{order.currency.toUpperCase()}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Status</b></IonCol>
                        <IonCol><IonChip color={order.status == 'success' ? 'success' : (order.status == 'expired' ? 'danger' : (order.status == 'processing') ? 'dark' : (order.status == 'processing') ? 'medium' : 'danger')}>{order.status}</IonChip></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Product</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.type == 'pay_bills' ? order.biller.billername : 'Transfer'}</IonCol>
                    </IonRow>

                    <IonRow hidden={order.type == 'send_money' ? false : true}>
                        <IonCol><b>Bank</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.customer != null ? order.customer.bank_name : ''}</IonCol>
                    </IonRow>

                    <IonRow hidden={order.type == 'send_money' ? false : true}>
                        <IonCol><b>Beneficiary Account Number</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.customer != null ? order.customer.account_number : ''}</IonCol>
                    </IonRow>
                    <IonRow hidden={order.type == 'send_money' ? false : true}>
                        <IonCol><b>Beneficiary Account Name</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.customer != null ? order.customer.account_name : ''}</IonCol>
                    </IonRow>
                    <IonRow hidden={order.type == 'receive_money' ? false : true}>
                        <IonCol><b>Bank</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.bankDetails != null ? order.bankDetails.bankName : ''}</IonCol>
                    </IonRow>

                    <IonRow hidden={order.type == 'receive_money' ? false : true}>
                        <IonCol><b>Account Number</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.bankDetails != null ? order.bankDetails.accountNumber : ''}</IonCol>
                    </IonRow>
                    <IonRow hidden={order.type == 'receive_money' ? false : true}>
                        <IonCol><b>Account Name</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.bankDetails != null ? order.bankDetails.accountName : ''}</IonCol>
                    </IonRow>
                    <IonRow hidden={order.type == 'pay_bills' ? false : true}>
                        <IonCol><b>Description</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.biller != null ? order.biller.categorydescription : ''}</IonCol>
                    </IonRow>
                    <IonRow hidden={order.type == 'pay_bills' ? false : true}>
                        <IonCol><b>Customer ID</b></IonCol>
                        <IonCol><IonSkeletonText hidden={disableSKText} animated />{order.validationFeedback != null ? order.validationFeedback['Customers'][0].fullName != '' ? order.validationFeedback['Customers'][0].fullName : order.validationFeedback['Customers'][0].customerId : 'NA'}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Date created</b></IonCol>
                        <IonCol><Moment format="Do MMM, YYYY h:mm a">{order.createdAt._seconds * 1000}</Moment></IonCol>
                    </IonRow>
                </IonGrid>
                {order.status == 'pending' ?
                    <IonButton expand="full" onClick={(e) => history.push("/pay/" + order.ref)} color="secondary" >Complete Payment</IonButton> : null
                }
                {order.status == 'failed' ?
                    <IonButton expand="full" onClick={(e) => history.push("/refund/" + order.ref)} color="danger" >Refund Order</IonButton> : null
                }
            </IonContent>
        </IonPage>
    )
}

export default ViewOrder;