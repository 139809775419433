import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton,
     IonIcon,withIonLifeCycle, IonBackButton, useIonViewWillEnter,} from '@ionic/react'
import axios from 'axios';
import { chevronBackOutline, shareSocialOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../state/GlobalState'
import { useHistory } from 'react-router-dom';

const Header: React.FC = () => {
const {hideToolbar, title, apiUrl} = useContext(GlobalContext);
const history = useHistory();
  const shareApp = async() =>{
    const _settings = await axios(apiUrl+'/getSettings');
    const settings = _settings.data;
    const info = await Device.getInfo();
    try{
    const share = await Share.share({
      title: 'Check out zypha',
      text: 'Buy airtime, data, pay bills and many more with zypa',
      url: settings[info.platform],
      dialogTitle: 'Share with contacts',
    });
    
  }catch(e:any){

  }
  }

  const navBack = () => {
    history.goBack();
  }
    return (
      <div><IonHeader hidden={hideToolbar}>
      <IonToolbar>
        <IonTitle>{title}</IonTitle>
        <IonButtons slot="start">
          <IonButton  fill="clear" onClick={(e) => navBack()} >
          <IonIcon icon={chevronBackOutline} />
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">
          <IonButton fill="clear" onClick={(e) => shareApp()}>
      <IonIcon icon={shareSocialOutline} /></IonButton>
      </IonButtons>
      </IonToolbar>
    </IonHeader>
    </div>
    )
}

export default Header;
