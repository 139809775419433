import axios from 'axios';
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../state/GlobalState';
import { Storage } from '@ionic/storage';
import { Device } from '@capacitor/device';
import {
  useIonLoading, useIonToast, useIonViewWillEnter, IonPage,
  IonContent, IonGrid, IonRow, IonCol, IonButton, withIonLifeCycle,
  IonSkeletonText, useIonViewWillLeave
} from '@ionic/react';

const SendMoney: React.FC<any> = ({ history }) => {

  const { apiUrl, setTitle, timeInterval } = useContext(GlobalContext);
  const [id, setID] = useState<any>('');
  const [device, setDevice] = useState<any>();
  const [present, dismiss] = useIonLoading();
  const [presentT1, dismissT1] = useIonToast();
  const [coinPrice, setCoinPrice] = useState<any>();
  const [bonusAndFees, setBonusAndFees] = useState<any>();
  const [details, setDetails] = useState<any>({ bank: { name: '' }, currency: '', accountNumber: '', coin: '', meta: { account_name: '' } });
  const [idToken_, setIDToken] = useState<any>();
  const [disableSKText, setDisableSKText] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  useIonViewWillEnter(async () => {
    setTitle('Send Money');
    const _id = await Device.getId();
    const _device = await Device.getInfo();
    setDevice(_device);
    setID(_id.uuid);
    const storage = new Storage();
    await storage.create();
    const idtoken__ = await storage.get('idToken_');
    setIDToken(idtoken__);
    const payload = await storage.get('payload');
    const result = await axios({
      url: apiUrl + '/validateAcc',
      method: 'post',
      responseType: 'json',
      data: {
        account_number: payload.accountNumber,
        account_bank: payload.bank.code
      }
    });

    fetchCoinPrices(payload.coin.pair);
    const feesAndBonus = await axios.get(apiUrl + '/getFeesAndBonus');
    setBonusAndFees(feesAndBonus.data);
    if (result.status == 200) {
      if (result.data.error == true) {
        presentT1({
          buttons: [{ text: 'close', handler: () => dismissT1() }],
          message: result.data.msg,
          color: 'danger'
        });
        history.push("/categories");
      } else {
        setDetails({ ...payload, ...result.data.data });
        setBtnDisabled(false);
      }
    } else {
      presentT1({
        buttons: [{ text: 'close', handler: () => dismissT1() }],
        message: result.statusText,
        color: 'danger'
      });
    };
    setDisableSKText(true);
  })
  const fetchCoinPrices = async (pair: any) => {
    const _coinsPrices = await axios.get(apiUrl + '/getCoinsPrices');
    _coinsPrices.data.forEach((_coinPrice: any) => {
      if (_coinPrice.pair == pair) {
        setCoinPrice(_coinPrice);
      }
    });

  }
  const sendMoney = async () => {
    //const smc = details.coin.split(' ');
    //const _1 = smc[1];
    //const _0 = smc[0];
    await present({
      message: 'Please wait'
    })
    const trx = await axios({
      url: apiUrl + '/createTransfer',
      method: 'post',
      responseType: 'json',
      data: {
        id: id,
        device: device,
        amount: details.amount,
        customer: {
          account_number: details.accountNumber,
          account_bank: details.bank.code,
          account_name: details.meta.account_name,
          bank_name: details.bank.name
        },
        idToken: idToken_,
        coin: details.coin,
        //smc: _1
      }
    });
    dismiss();
    if (trx.data.error == true) {
      presentT1({
        buttons: [{ text: 'close', handler: () => dismissT1() }],
        message: trx.data.msg,
        color: 'danger'
      });
    } else {
      history.push('/pay/' + trx.data.ref);
    }
  }

  useIonViewWillLeave(() => {
    setDetails({ bank: { name: '' }, currency: '', accountNumber: '', coin: { name: '' }, meta: { account_name: '' } });
    setDisableSKText(false);
  })

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol><b>Bank</b></IonCol>
            <IonCol><IonSkeletonText hidden={disableSKText} animated />{details.bank.name}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol><b>Account Number</b></IonCol>
            <IonCol><IonSkeletonText hidden={disableSKText} animated />{details.accountNumber}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol><b>Account Name</b></IonCol>
            <IonCol><IonSkeletonText hidden={disableSKText} animated />{details.meta.account_name}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol><b>Coin</b></IonCol>
            <IonCol><IonSkeletonText hidden={disableSKText} animated />{details.coin.name}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol><b>Rate</b></IonCol>
            <IonCol><IonSkeletonText hidden={disableSKText} animated />{coinPrice?.price}{details.currency.toUpperCase()}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol><b>Fee</b></IonCol>
            <IonCol><IonSkeletonText hidden={disableSKText} animated />{bonusAndFees?.fees?.trxFees}%</IonCol>
          </IonRow>
          <IonRow>
            <IonCol><b>Amount</b></IonCol>
            <IonCol><IonSkeletonText hidden={disableSKText} animated />{details.amount}{details.currency.toUpperCase()}</IonCol>
          </IonRow>

        </IonGrid>
        <IonButton expand="block" disabled={btnDisabled} onClick={() => sendMoney()}>Complete Payment</IonButton>
        <IonButton expand="block" color="danger" onClick={(e) => history.push('/categories')}>Cancel</IonButton>
      </IonContent>
    </IonPage>
  )
}

export default withIonLifeCycle(SendMoney)