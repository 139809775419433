import React from 'react'

const BonusBalance:React.FC<any>= ({balance}) => {
    return (
        <div style={{textAlign:"center",backgroundColor:'#3171e01a',margin:'auto',padding:'1em'}}>
        <h4>Bonus Balance</h4>
        <span>{balance} NGN</span></div>
    )
}

export default BonusBalance
