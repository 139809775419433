import {
  IonPage, IonItem, IonLabel, IonContent, IonNote,
  IonText, IonList, useIonViewWillEnter, withIonLifeCycle,
  useIonLoading, useIonToast, IonSegment, IonSegmentButton, IonButton,
  IonInput, IonSelect, IonSelectOption, IonSpinner,
  useIonViewWillLeave,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
  IonSlide,
  IonSlides,
  useIonActionSheet,
  useIonViewDidEnter
} from '@ionic/react'
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../state/GlobalState';
import { Storage } from '@ionic/storage';
import { addCircleOutline, alertCircle, copyOutline } from 'ionicons/icons';
import { Clipboard } from '@capacitor/clipboard';
import { setInterval } from 'timers';


const Categories: React.FC<any> = ({ history }) => {

  const { apiUrl, setTitle, isAuth, timerInterval } = useContext(GlobalContext);
  const [categories, setCategories] = useState<any>([]);
  const [linkedAccs, setLinkedAccs] = useState<any>([]);
  const [hideSM, setSM] = useState<any>('block');
  const [hideCat, setCat] = useState<any>('none');
  const [segment, setSegment] = useState<any>('send_money');
  const [present, dismiss] = useIonLoading();
  const [presentT1, dismissT1] = useIonToast();
  const [coin, setCoin] = useState<any>();
  const [coins, setCoins] = useState<any>([]);
  const [coinsPrices, setCoinsPrices] = useState<any>([]);
  const [bank, setBank] = useState<any>();
  const [banks, setBanks] = useState<any>([]);
  const [accountNumber, setAccountNumber] = useState<any>();
  const [amount, setAmount] = useState<any>();
  const [search, setSearch] = useState<any>();
  const [disableSpinner, setDisableSpinner] = useState<any>(true);
  const [disableSpinner2, setDisableSpinner2] = useState<any>(true);
  const [error_loading_billers, setLoadingBillers] = useState<any>(true);
  const [idToken_, setIDToken] = useState<any>();
  function dynamicSort(property: any) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substring(1);
    }
    return function (a: any, b: any) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  function loadCat() {
    setDisableSpinner(false);
    setLoadingBillers(false);
    axios.get(apiUrl + '/getSVACategories')
      .then((result) => {
        setCategories(result.data.categorys.sort(dynamicSort('categoryname')));
        setLoadingBillers(false);
      }).catch((error: any) => {
        setLoadingBillers(true);
      }).finally(() => {
        setDisableSpinner(true);
      })
  }
  useIonViewWillEnter(async () => {
    setTitle('Send Money');
    fetchCoins();
    fetchBanks();
    //setInterval(fetchCoinsPrices, timerInterval);
    loadCat();
    const storage = new Storage();
    await storage.create();
    const idtoken__ = await storage.get('idToken_');
    setIDToken(idtoken__);
  });
  useIonViewWillLeave(async () => {
    setAccountNumber('');
    setAmount('');
    setSegment('send_money');
  });
  const handleSegmentChange = (e: any) => {
    switch (e) {
      case 'pay_bills':
        setTitle('Pay Bill');
        setSM('none');
        setCat('block');
        break;
      case 'receive_money':
        setTitle('Receive Money');
        loadLinkedAccount();
        break;
      case 'send_money':
        setTitle('Send Money');
        setCat('none');
        setSM('block');
        break;
    }
    setSegment(e);
  }
  const handleClick = async (category: any) => {
    const storage = new Storage();
    await storage.create();
    await storage.set('category', category);
    history.push("/categories/" + category.categoryid);
  }
  const fetchCoins = async () => {
    const _coins = await axios.get(apiUrl + '/getCoins');
    setCoins(_coins.data);
    setCoin(_coins.data[0]);
  }
  const fetchBanks = async () => {
    const _banks = await axios.get(apiUrl + '/getBanks');
    setBanks(_banks.data.data.sort(dynamicSort("name")));
    setBank(_banks.data.data.sort(dynamicSort("name"))[0]);
  }
  const fetchCoinsPrices = async () => {
    const _coinsPrices = await axios.get(apiUrl + '/getCoinsPrices');
    setCoinsPrices(_coinsPrices.data);
  }
  async function loadLinkedAccount() {

    setDisableSpinner2(false);
    const _linkedAccounts = await axios({
      url: apiUrl + '/getLinkedAccounts',
      method: 'post',
      data: {
        idToken: idToken_
      },
      responseType: 'json'
    });
    setLinkedAccs(_linkedAccounts.data.error ? [] : _linkedAccounts.data);

    setDisableSpinner2(true);
  }
  const trySendMoney = async () => {
    if (amount > 0 && accountNumber != '') {
      const storage = new Storage();
      await storage.create();
      await storage.set('payload', {
        accountNumber: accountNumber,
        bank: bank,
        coin: coin,
        amount: amount,
        currency: 'ngn'
      });
      history.push("/send-money/confirm");
    }
    else {
      presentT1({
        buttons: [{ text: 'close', handler: dismissT1 }],
        message: 'All fields are required',
        color: 'danger',
        position: 'top'
      });
    }
  }
  const navLinkedAcc = async (id: any) => {
    history.push('/view-linked-account/' + id)
  }

  return (
    <IonPage>
      <IonContent>
        <IonSegment style={{ padding: '1%' }} value={segment} onIonChange={(e) => handleSegmentChange(e.detail.value)} mode="ios">
          <IonSegmentButton value="send_money" >
            <IonLabel>Send Money</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="receive_money">
            <IonLabel>Receive Money</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="pay_bills">
            <IonLabel>Pay Bills</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <div style={{ display: (segment == 'send_money' ? 'block' : 'none') }}>
          <IonItem>
            <IonLabel position="stacked">Beneficiary Bank</IonLabel>
            <IonSelect onIonChange={e => setBank(e.detail.value)} value={bank}>
              {
                banks.map((bank: any) =>
                (
                  <IonSelectOption value={bank}>{bank.name}</IonSelectOption>
                )
                )
              }
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Beneficiary Account Number</IonLabel>
            <IonInput value={accountNumber} onIonChange={(e) => setAccountNumber(e.detail.value)} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Amount</IonLabel>
            <IonInput value={amount} onIonChange={(e) => setAmount(e.detail.value)} />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Coin</IonLabel>
            <IonSelect onIonChange={e => setCoin(e.detail.value)} value={coin} >
              {
                coins.map((coin: any) =>
                (
                  <IonSelectOption value={coin}>{coin.name}</IonSelectOption>
                )
                )
              }</IonSelect>
          </IonItem>
          <IonButton expand="full" onClick={() => trySendMoney()} >Validate</IonButton>
        </div>

        <div style={{ display: (segment == 'receive_money' ? 'block' : 'none') }}>
          <div style={{ display: (isAuth == false ? 'block' : 'none'), textAlign: 'center', padding: "1.5rem" }}>
            <IonLabel>You must be logged in to use this service</IonLabel>
            <IonButton expand="full" onClick={(e) => { history.push('/login'); }}>Login</IonButton>
          </div>
          <div style={{ display: (isAuth == true ? 'block' : 'none') }}>
            <IonChip color="tertiary"><IonIcon icon={alertCircle} /> <IonLabel>Get instant payout to your bank account once coins are recieved at your wallet address.</IonLabel></IonChip>
            <div style={{ textAlign: 'center' }}>
              <IonSpinner hidden={disableSpinner2} />
            </div>
            <IonList>

              {
                linkedAccs.map((linkedAcc: any) =>
                (
                  <IonItem detail={true} onClick={() => navLinkedAcc(linkedAcc.ref)} >
                    <IonLabel>
                      <IonText>{linkedAcc.accountNumber}</IonText><br />
                      <IonNote>{linkedAcc.coin.name}</IonNote>
                    </IonLabel>
                  </IonItem>
                ))
              }

            </IonList>
            <div>
              <IonButton expand="full" color="dark" fill="outline" onClick={(e) => { history.push("/link-bank-account") }}><IonIcon icon={addCircleOutline} />Link Account</IonButton>
            </div>
          </div></div>
        <div style={{ display: (segment == 'pay_bills' ? 'block' : 'none') }}>
          {
            /*<IonSearchbar placeholder="Search Biller" 
             value={search} onIonChange={(e) => setSearch(e.detail.value)} showCancelButton="focus"/>
           */
          }
          <div style={{ textAlign: 'center' }}>
            <IonSpinner hidden={disableSpinner} />
            <div style={{ textAlign: 'center', padding: '2rem', display: (error_loading_billers == true ? 'block' : 'none') }}>
              <IonText>Unable to load billers, please check network connection</IonText><br />
              <IonButton onClick={(e) => loadCat()}>Retry</IonButton>
            </div>
          </div>
          <IonList>
            {
              categories.map((category: any) =>
              (
                <IonItem detail={true} onClick={() => handleClick(category)} >
                  <IonLabel>
                    <IonText>{category.categoryname}</IonText><br />
                    <IonNote>{category.categorydescription}</IonNote>
                  </IonLabel>
                </IonItem>
              ))}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Categories;