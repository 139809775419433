import { IonPage, IonItem, IonContent, IonNote, IonIcon, IonText, IonImg, useIonToast, useIonViewWillEnter, IonChip, IonSkeletonText, useIonViewDidLeave, IonButton } from '@ionic/react'
import axios from 'axios';
import { copyOutline } from 'ionicons/icons'
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../state/GlobalState';
import { Clipboard } from '@capacitor/clipboard';
import { matchPath } from 'react-router';


const PayOrder: React.FC<any> = ({ history, match }) => {

    const { apiUrl, setTitle } = useContext(GlobalContext);
    const [order, setOrder] = useState<any>({ coin: '', type: '', customer: { account_name: '' }, currency: '', biller: { billername: '' }, validationFeedback: { Customers: [{ fullName: '' }] }, rates: { usd: 0, meta: { ngn: 0 } } });
    const [qrLink, setQrLink] = useState<any>();
    const [disableSKText, setDisableSKText] = useState(false);
    const [present, dismiss] = useIonToast();
    const loadOrder = async () => {

        const match2 = matchPath(history.location.pathname, {
            path: match.path,
            exact: match.isExact,
            strict: false
        });
        const params: any = match2?.params;
        const _order = await axios({
            url: apiUrl + '/getOrder',
            method: 'post',
            data: {
                ref: params.orderid
            },
            responseType: 'json'
        });
        setOrder(_order.data);
        setQrLink('https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' + _order.data.address);
        setDisableSKText(true);
    }
    const copyAddr = async () => {
        await Clipboard.write({
            string: order.address
        });
        present({
            buttons: [{ text: 'close', handler: () => dismiss() }],
            message: "Address copied"
        });
    }
    const copyID = async () => {
        await Clipboard.write({
            string: order.ref
        });
        present({
            buttons: [{ text: 'close', handler: () => dismiss() }],
            message: "ID copied"
        });
    }
    useIonViewWillEnter(async () => {
        setTitle('Pay Order');
        await loadOrder();
    });

    return (
        <IonPage>
            <IonContent >
                <h3 style={{ textAlign: 'center' }}>{order.type == 'pay_bills' ? order.biller.billername : 'Transfer'}</h3>
                <IonItem><IonText style={{ margin: 'auto' }} class="ion-text-center" color="dark">

                    <IonSkeletonText hidden={disableSKText} animated />{order.type == 'pay_bills' ? order.validationFeedback['Customers'][0].fullName : order.customer.account_name}
                </IonText></IonItem>
                <IonItem>
                    <IonText style={{ margin: 'auto', fontWeight: '200' }} onClick={() => copyID()}
                        class="ion-text-center">
                        <IonSkeletonText hidden={disableSKText} animated />{order.ref}<IonIcon icon={copyOutline} onClick={() => copyID()} /></IonText>
                </IonItem>
                <IonItem >
                    <IonImg src={qrLink} style={{ width: '200px', height: '200px', margin: 'auto' }} /></IonItem>
                <IonItem >
                    <IonText style={{ margin: 'auto', fontWeight: '200' }} onClick={() => copyAddr()}
                        class="ion-text-center">
                        <IonSkeletonText hidden={disableSKText} animated />{order.address}</IonText><IonIcon icon={copyOutline} onClick={() => copyAddr()} />
                </IonItem>
                <IonItem >
                    <IonText style={{ margin: 'auto' }}
                        class="ion-text-center">Send {order.amount}{order.currency.toUpperCase()} ~ {order.amountToSend}{order.coin} to the above address</IonText>
                </IonItem>

                <IonItem   >
                    <IonText style={{ margin: 'auto' }} class="ion-text-center" color="medium">
                        <IonChip >
                            <small>Rate: {((order.rates.meta.ngn).toFixed(2))}{order.currency.toUpperCase()}</small></IonChip></IonText>
                </IonItem>

                <IonItem   >
                    <IonText style={{ margin: 'auto' }} class="ion-text-center" >
                        <IonChip color="danger">
                            <small>Fee: {order.fee}{order.coin}</small></IonChip></IonText>
                </IonItem>
                <IonItem hidden={order.applyBonus != null ? !order.applyBonus : true}>
                    <IonText style={{ margin: 'auto' }} class="ion-text-center" color="medium"><small>{order.bonusAmount}{order.currency.toUpperCase()} discount applied</small></IonText>
                </IonItem>
                <IonItem >
                    <IonNote style={{ margin: 'auto' }}
                        class="ion-text-center" color="warning">This payment address will expire after 1 hour. Send exact amount to above address.</IonNote>
                </IonItem>
                <IonItem >
                    <IonNote style={{ margin: 'auto' }}
                        class="ion-text-center" color="secondary">Fiat equivalence may differ due to exchange rate and price fluctuations.</IonNote>
                </IonItem>
                <IonButton expand='block' href={'/orders/' + order.ref}>I have made payment</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default PayOrder