import { withIonLifeCycle, IonFooter} from '@ionic/react'
import Moment from 'react-moment';

const Footer: React.FC = () => {
    return (
      <div>
          <IonFooter>
          </IonFooter>
    </div>
    )
}

export default withIonLifeCycle(Footer);
