import { Redirect, Route } from 'react-router-dom';
import {
  IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,
  useIonViewDidEnter, withIonLifeCycle
} from '@ionic/react';
import { cartOutline, chatbubblesOutline, listOutline, logInOutline, personOutline } from 'ionicons/icons';
import React, { useContext } from 'react';
import { GlobalContext } from '../state/GlobalState';

import { IonReactRouter } from '@ionic/react-router';
import { Switch } from 'react-router';
import ViewOrderContainer from '../components/ViewOrderContainer';
import SocialFeedback from '../components/SocialFeedback';
import RefundOrderContainer from '../components/RefundOrderContainer';
import Login from '../pages/Login';
import Kyc from '../pages/Kyc';
import KycVerify from '../pages/KycVerify';
import Recover from '../pages/Recover';
import Join from '../pages/Join';
import Categories from '../pages/Categories';
import Billers from '../pages/Billers';
import NewOrder from '../pages/NewOrder';
import ViewOrder from '../pages/ViewOrder';
import Orders from '../pages/Orders';
import PayOrder from '../pages/PayOrder';
import Account from '../pages/Account';
import ReferralBonus from '../pages/ReferralBonus';
import Referrals from '../pages/Referrals';
import Support from '../pages/Support';
import SupportFeedback from '../pages/SupportFeedback';
import RefundOrder from '../pages/RefundOrder';
import SendMoney from '../pages/SendMoney';
import LinkBankAccount from '../pages/LinkBankAccount';
import EditLinkedAccount from '../pages/EditLinkedAccount';
import Header from './Header';
import DojahKyc from '../pages/DojahKyc';
import ViewLinkedAccount from '../pages/ViewLinkedAccount';
const Tabs: React.FC = () => {
  const { hideTabs, isAuth, isVerified, setAuth, setIDToken_ } = useContext(GlobalContext);
  return (

    <IonReactRouter>
      <Header />
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/login" component={Login} exact={true} render={props => { return <Login {...props} /> }} />
          <Route path="/kyc" component={Kyc} exact={true} render={props => { return <Kyc {...props} /> }} />
          <Route path="/kyc/verify" component={KycVerify} exact={true} render={props => { return <KycVerify {...props} /> }} />
          <Route path="/recover" component={Recover} exact={true} render={props => { return <Recover {...props} /> }} />
          <Route path="/join" component={Join} exact={true} render={props => { return <Join {...props} /> }} />
          <Route path="/send-money/confirm" component={SendMoney} exact={true} render={props => { return <SendMoney {...props} /> }} />
          <Route path="/categories" exact={true} render={props => { return isAuth ? (isVerified ? <Categories {...props} /> : <DojahKyc {...props} />) : <Login {...props} /> }} />
          <Route path="/categories/:categoryid" component={Billers} render={props => { return <Billers {...props} /> }} />
          <Route path="/billers/:billerid" component={NewOrder} render={props => { return <NewOrder {...props} /> }} />
          <Route path="/history" exact={true} render={props => { return isAuth ? (isVerified ? <Orders {...props} /> : <DojahKyc {...props} />) : <Login {...props} /> }} />
          <Route path="/orders/:orderid" render={props => { return <ViewOrder {...props} /> }} />
          <Route path="/pay/:orderid" component={PayOrder} render={props => { return <PayOrder {...props} /> }} />
          <Route path="/refund/:orderid" component={RefundOrder} render={props => { return <RefundOrder {...props} /> }} />
          <Route path="/account" component={Account} exact={true} render={props => { return isAuth ? <Account {...props} /> : <Login {...props} /> }} />
          <Route path="/support" exact={true} render={props => { return <Support {...props} /> }} />
          <Route path="/support/feedback" component={SupportFeedback} exact={true} render={props => { return <SupportFeedback {...props} /> }} />
          <Route path="/referrals" component={Referrals} exact={true} render={props => { return <Referrals {...props} /> }} />
          <Route path="/referrals/bonus" component={ReferralBonus} exact={true} render={props => { return <ReferralBonus {...props} /> }} />
          <Route path="/feedback" component={SocialFeedback} exact={true} render={props => { return <SocialFeedback {...props} /> }} />
          <Route path="/view/:ref" component={ViewOrderContainer} render={props => { return <ViewOrderContainer {...props} /> }} />
          <Route path="/refund/:ref" component={RefundOrderContainer} render={props => { return <RefundOrderContainer {...props} /> }} />
          <Route path="/view-linked-account/:accountid" component={ViewLinkedAccount} exact={true} render={props => { return <ViewLinkedAccount {...props} /> }} />
          <Route path="/link-bank-account" component={LinkBankAccount} exact={true} render={props => { return <LinkBankAccount {...props} /> }} />
          <Route path="/edit-linked-account/:accid" component={EditLinkedAccount} render={props => { return <EditLinkedAccount {...props} /> }} />
          <Route path="/dojah/:email" component={DojahKyc} render={props => { return <DojahKyc {...props} /> }} />

          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom" hidden={hideTabs}>
          <IonTabButton tab="tab1" href="/categories">
            <IonIcon icon={cartOutline} />
            <IonLabel>New Order</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/history">
            <IonIcon icon={listOutline} />
            <IonLabel>My Orders</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/support">
            <IonIcon icon={chatbubblesOutline} />
            <IonLabel>Support</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab4" href={isAuth ? "/account" : "/login"}>
            <IonIcon icon={isAuth ? personOutline : logInOutline} />
            <IonLabel>{isAuth ? "Account" : "Login/Signup"}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  )
}

export default withIonLifeCycle(Tabs);
