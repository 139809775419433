export default (state: any, action: any) => {
    switch(action.type){
          case 'SET_USER':
            return {
              ...state,
              user: action.payload
            }
            case 'SET_AUTH':
              return {
                ...state,
                isAuth: action.payload
              }
              case 'SET_VERIFIED':
              return {
                ...state,
                isVerified: action.payload
              }
              case 'HIDE_TABBAR':
              return {
                ...state,
                hideTabs: action.payload
              }
              case 'HIDE_TOOLBAR':
              return {
                ...state,
                hideToolbar: action.payload
              }
              case 'SET_TITLE':
                return {
                  ...state,
                  title: action.payload
                }
                case 'SET_ID_TOKEN':
                  return {
                    ...state,
                    idToken_: action.payload
                  }
      default:
            return state;
    }
  }