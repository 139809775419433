import {
    IonPage, IonItem, IonLabel, IonButton, IonContent,
    IonText, IonCol, IonGrid, IonRow, IonList, IonListHeader, useIonViewWillEnter,
    useIonAlert, useIonToast, IonSkeletonText
} from '@ionic/react'
import React, { useContext, useState } from 'react';
import { getAuth, signOut } from "firebase/auth";
import axios from 'axios';
import app from '../firebase.config.js';
import Moment from 'react-moment';
import { GlobalContext } from '../state/GlobalState';
import BonusBalance from '../components/BonusBalance';
import { Dialog } from '@capacitor/dialog';
import { Toast } from "@capacitor/toast";

const Account: React.FC<any> = ({ history }) => {

    const [present] = useIonAlert();
    const [presentT1] = useIonToast();
    const { apiUrl, setTitle, setAuth, setIDToken_, idToken_ } = useContext(GlobalContext);
    const [user, setUser] = useState<any>({ username: '', bonusBalance: 0, email: '', referralCode: '', referral: { username: '' }, createdAt: { _seconds: 0 } });
    const [disableSKText, setDisableSKText] = useState(false);
    useIonViewWillEnter(async () => {
        setTitle('Account');
        const auth = getAuth(app);
        const _user = await axios({
            url: apiUrl + '/getUser',
            method: 'post',
            data: {
                idToken: idToken_
            },
            responseType: 'json'
        });
        setUser({ ..._user.data, ...auth.currentUser });
        setDisableSKText(true);
    });

    const logout = () => {
        setAuth(false);
        setIDToken_(null);
        history.push('/login');
    }

    const requestAccDel = async () => {
        const confirm = await Dialog.confirm({
            title: 'Delete Account',
            message: '😔 Hi, so sad to see you want to go but are you sure you wish to carry on with this action?'
        });
        console.log(confirm);
        if (confirm.value) {
            Toast.show({
                text: '⚠️ Account deletion has been requested!!!',
                position: 'bottom'
            });
            await axios({
                url: apiUrl + '/disableUser',
                method: 'post',
                data: {
                    idToken: idToken_
                },
                responseType: 'json'
            });
            history.push('/login');
        } else {

        }
    }
    return (
        <IonPage>
            <IonContent>
                <BonusBalance balance={user.bonusBalance}></BonusBalance>
                <IonGrid>
                    <IonRow>
                        <IonCol><b>Username</b></IonCol>
                        <IonCol>
                            <IonSkeletonText hidden={disableSKText} animated />
                            {user.username}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Email</b></IonCol>
                        <IonCol>
                            <IonSkeletonText hidden={disableSKText} animated />
                            {user.email}</IonCol>
                    </IonRow><IonRow>
                        <IonCol><b>Referral Code</b></IonCol>
                        <IonCol>

                            <IonSkeletonText hidden={disableSKText} animated />
                            {user.referralCode}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Referred By</b></IonCol>
                        <IonCol>

                            <IonSkeletonText hidden={disableSKText} animated />
                            {user.referral.username != '' ? user.referral.username : 'NA'}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol><b>Date joined</b></IonCol>
                        <IonCol>
                            <IonSkeletonText hidden={disableSKText} animated /><Moment format="Do MMM, YYYY h:mm a">{user.createdAt._seconds * 1000}</Moment></IonCol>
                    </IonRow>
                </IonGrid>
                <IonList>
                    <IonListHeader>
                        <b>Stats</b>
                    </IonListHeader>
                    <IonItem>
                        <IonLabel>Total Bill Purchased</IonLabel>
                        <IonText>

                            <IonSkeletonText hidden={disableSKText} animated />{user.totalBillsPurchased} NGN</IonText>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Total Bonus Earned</IonLabel>
                        <IonText>
                            <IonSkeletonText hidden={disableSKText} animated />{user.totalBonusEarned} NGN</IonText>
                    </IonItem>
                </IonList>
                <IonButton expand="block" hidden={!disableSKText} onClick={() => history.push('/referrals/bonus')}>Bonus Earnings</IonButton>
                <IonButton expand="block" onClick={logout} color="danger">Sign Out</IonButton>
                <IonButton expand="block" onClick={requestAccDel} color="dark">Delete Account</IonButton>

            </IonContent>
        </IonPage>
    )
}

export default Account