import axios from 'axios';
import React, { createContext, useReducer} from 'react';
import { ENV } from '../env';
import AppReducer from './AppReducer';

const AppData : any = {
    user : {meta:{}},
    isAuth: false,
    isVerified: false,
    hideTabs: false,
    hideToolbar: false,
    title: 'Zypha',
    env: ENV,
    apiUrl: 'https://us-central1-zypha-40180.cloudfunctions.net',
    idToken_: '',
    timerInterval: 10000
};

export const GlobalContext = createContext(AppData);

export const GlobalProvider = (params:any) => {
 const [state, dispatch] = useReducer(AppReducer, AppData);

 //Actions


 function setUser(action: any){
     return dispatch({
         type: 'SET_USER',
         payload: action
     })
 }

 function setAuth(action: any){
     return dispatch({
         type: 'SET_AUTH',
         payload: action
     })
 }

 function setVerified(action: any){
    return dispatch({
        type: 'SET_VERIFIED',
        payload: action
    })
}

 function hideToolBar(action: any)
 {
    return dispatch({
        type: 'HIDE_TOOLBAR',
        payload: action
    })
 }

 function hideTabBar(action: any)
 {
    return dispatch({
        type: 'HIDE_TABBAR',
        payload: action
    })
 }

 function setTitle(action: any)
 {
     return dispatch({
         type: 'SET_TITLE',
         payload: action
     })
 }

 function setIDToken_(action: any)
 {
     return dispatch({
         type: 'SET_ID_TOKEN',
         payload: action
     })
 }

 

 return (<GlobalContext.Provider value={{
   ...state, setUser, setAuth, hideToolBar, hideTabBar, setTitle, setIDToken_, setVerified
 }}>
     {params.children}
 </GlobalContext.Provider>); 
};
