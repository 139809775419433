import {
    IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent,
    useIonLoading, useIonToast, useIonViewWillEnter, withIonLifeCycle
} from '@ionic/react'
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../state/GlobalState';
import app from '../firebase.config.js';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import axios from 'axios';
const Join: React.FC<any> = ({ history }) => {
    const [username, setUsername] = useState<any>('');
    const [email, setEmail] = useState<any>('');
    const [password, setPassword] = useState<any>('');
    const [cpassword, setCPassword] = useState<any>('');
    const [referral, setReferral] = useState<any>('');
    const [present, dismiss] = useIonLoading();
    const [presentT1, dismissT1] = useIonToast();
    const { apiUrl, setTitle } = useContext(GlobalContext);
    useIonViewWillEnter(async () => {
        setTitle('Create Account');
    });
    const join = async () => {
        try {
            if ((cpassword !== password) && (cpassword != null)) {
                presentT1({
                    buttons: [{ text: 'close', handler: () => dismissT1() }],
                    message: "Password do not match",
                    color: 'danger'
                });
            } else {
                await present({
                    message: 'Please wait'
                });

                const _result = await axios({
                    url: apiUrl + '/createUser',
                    method: 'post',
                    data: {
                        email: email,
                        password: password,
                        referral_code: referral,
                        username: username
                    },
                    responseType: 'json'
                });

                if (_result.data.error) {
                    presentT1({
                        buttons: [{ text: 'close', handler: () => dismissT1() }],
                        message: _result.data.msg,
                        color: 'danger',
                        position: 'top'
                    });
                } else {
                    presentT1({
                        message: 'Account created successfully, please check inbox or spam folder for verification link',
                        color: 'success',
                        duration: 5000,
                        position: 'top'
                    });
                    history.push('/login');

                }
            }
        } catch (e: any) {
            alert(e.message);
        }finally {
            dismiss();
        }
    }
    return (
        <IonPage>
            <IonContent>
                <h1 style={{ textAlign: 'center' }}>Create An Account</h1>

                <IonItem>
                    <IonLabel position="stacked">Username</IonLabel>
                    <IonInput placeholder="Username" autofocus={true}
                        clearInput={true} value={username} onIonChange={(e) => setUsername(e.detail.value)} />
                </IonItem> <IonItem>
                    <IonLabel position="stacked">Email</IonLabel>
                    <IonInput placeholder="Email" autofocus={true} clearInput={true}
                        type="email" value={email} onIonChange={(e) => setEmail(e.detail.value)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Password</IonLabel>
                    <IonInput placeholder="Password" clearInput={true} type="password"
                        value={password} onIonChange={(e) => setPassword(e.detail.value)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Confirm Password</IonLabel>
                    <IonInput placeholder="Confirm Password" clearInput={true} type="password"
                        value={cpassword} onIonChange={(e) => setCPassword(e.detail.value)} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Referral Code</IonLabel>
                    <IonInput placeholder="Referral Code" clearInput={true}
                        value={referral} onIonChange={(e) => setReferral(e.detail.value)} />
                </IonItem>
                <IonButton expand="full" onClick={join}>Create Account</IonButton>
                <IonButton style={{ marginTop: '2%' }} expand="full" fill="clear" onClick={(e) => history.push('/login')}>Login</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default withIonLifeCycle(Join)


