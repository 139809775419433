import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent,
    useIonLoading, useIonToast, useIonViewWillEnter, withIonLifeCycle, IonSelect, IonSelectOption } from '@ionic/react'
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../state/GlobalState';
import app from '../firebase.config.js';
import { getAuth} from "firebase/auth";
import axios from 'axios';

const EditLinkedAccount:React.FC<any> = ({history}) => {
   const [present, dismiss] = useIonLoading();
   const [presentT1, dismissT1] = useIonToast();
   const {apiUrl, setTitle} = useContext(GlobalContext);
   const [bank, setBank] = useState<any>();
   const [banks, setBanks] = useState<any>([]);
   const [accountNumber, setAccountNumber] = useState<any>();
   function dynamicSort(property: any)
 {
     var sortOrder = 1;
     if(property[0] === "-"){
         sortOrder = -1;
         property = property.substring(1);
     }
     return function (a:any,b:any) {
         var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
         return result * sortOrder;
     }
 }
   useIonViewWillEnter(async () =>  {
       setTitle('Edit Linked Bank Account');
   });
   
   return (
       <IonPage>
           <IonContent>
               <h1 style={{textAlign:'center'}}>Edit Linked Bank Account</h1>
               <IonItem>
                <IonLabel position="stacked">Bank</IonLabel>
                <IonSelect onIonChange={e => setBank(e.detail.value)} value={bank}>
              {
                    banks.map((bank :any)=> 
                    (
                    <IonSelectOption  value={bank}>{ bank.name}</IonSelectOption>
                    )
                    )
                }
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Account Number</IonLabel>
                <IonInput value={accountNumber} onIonChange={(e)=>setAccountNumber(e.detail.value) }/>
            </IonItem>
            <IonButton expand="full" >Validate & Link</IonButton>
               </IonContent>
           </IonPage>
   )
}

export default withIonLifeCycle(EditLinkedAccount)


