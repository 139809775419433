import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonText, IonSelect, IonTextarea, 
    IonButton, IonLabel, IonSelectOption, IonItem, IonInput, useIonLoading } from "@ionic/react";
import axios from "axios";
import { Device } from '@capacitor/device';
import { useState } from "react";

const SocialFeedback: React.FC<any> = ({history,match}) => {
    const [rating, setRating] = useState<any>('1');
    const [email, setEmail] = useState<any>();
    const [comment, setComment] = useState<any>();
    const [isSent, setIsSent] = useState<any>(false);
    const apiUrl = 'https://us-central1-zypha-40180.cloudfunctions.net';
    const [presentL1, dismissL1] = useIonLoading();
    
    const sendFeedback = async() => {
        presentL1({
        message: 'Please wait'
      })
        const info = await Device.getInfo();
        const result = await axios({
        url:apiUrl+'/sendFeeback',
        method: 'post',
        responseType: 'json',
        data: {
          device: info, 
          email: email,
          rating: rating,
          comment: comment}});
        setIsSent(true);
        dismissL1()
    }
    const reset = async() => {
        setEmail('');
        setComment('');
        setIsSent(false);
        setRating('1');
    }
  return (
      <IonPage> 
          <IonHeader>
      <IonToolbar>
        <IonTitle>Feedback</IonTitle>
      </IonToolbar>
    </IonHeader>
    <br/><br/>
          <IonContent hidden={isSent}>
              <IonItem>
              <IonText>Have something to say about our app? We are more than happy to hear from you. Tell us anything be it a dislike, more features you wish added or want to compliment us 😉. Go ahead drop it in box below</IonText>
              </IonItem>
              <IonItem><IonLabel position="stacked">How will you rate us</IonLabel>
              <IonSelect onIonChange={e => setRating(e.detail.value)} value={rating}>
                  <IonSelectOption value="1">⭐</IonSelectOption>
                  <IonSelectOption value="2">⭐⭐</IonSelectOption>
                  <IonSelectOption value="3">⭐⭐⭐</IonSelectOption>
                  <IonSelectOption value="4">⭐⭐⭐⭐</IonSelectOption>
                  <IonSelectOption value="5">⭐⭐⭐⭐⭐</IonSelectOption>
              </IonSelect></IonItem><IonItem>
              <IonLabel position="stacked">Feedback</IonLabel>
              <IonTextarea onIonChange={e => setComment(e.detail.value)} value={comment}>
                  </IonTextarea></IonItem><IonItem>
              <IonLabel position="stacked">Your Email</IonLabel>
              <IonInput onIonChange={e => setEmail(e.detail.value)} value={email}>
                  </IonInput></IonItem>
              <IonButton expand="full" color="success" onClick={(e) => sendFeedback()}>Submit</IonButton>
          </IonContent>
          
          <IonContent hidden={!isSent}>
              <IonText>🥳🥳🥳Thanks for your feedback</IonText>
              <IonButton expand="full" color="success" onClick={(e) => reset()}>Leave another comment</IonButton>
         
          </IonContent>
      </IonPage>
  )
}

export default SocialFeedback;


