import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyCJfx1n13d_DaB13_MR9ql4C-65wqUIUpM",
    authDomain: "zypha-40180.firebaseapp.com",
    databaseURL: "https://zypha-40180-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "zypha-40180",
    storageBucket: "zypha-40180.appspot.com",
    messagingSenderId: "764032156447",
    appId: "1:764032156447:web:40d6a6c8bea880022d662a",
    measurementId: "G-5R2YHXKNBW"
};

const app = initializeApp(firebaseConfig);

export default app;