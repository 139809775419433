import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { IonPage, IonItem,  IonButton, IonContent, IonNote, IonIcon, IonText, 
    useIonToast, useIonViewWillEnter } from '@ionic/react'
import axios from 'axios';
import { copyOutline} from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../state/GlobalState';
import { Clipboard } from '@capacitor/clipboard';

const Support:React.FC<any> = ({history}) => {
const {apiUrl, setTitle} = useContext(GlobalContext);
const [id, setID] = useState<any>();
const [settings, setSettings] = useState<any>({telegram:'',slack:'',discord:'',livechat:'',whatsapp:''})
const [present, dismiss] = useIonToast();
useIonViewWillEnter(async () =>  {
    setTitle('Support');
    const _id = await Device.getId();
    setID(_id.uuid);
    const _settings = await axios(apiUrl+'/getSettings');
    setSettings(_settings.data);
  })
  const copy = async(data:any) =>{
    await Clipboard.write({
        string: data
      });
      present({
        buttons: [{ text: 'hide', handler: () => dismiss() }],
        message: "Copy successful"
      });
}
  const openUri = async(_url: any) =>{
    await Browser.open({url: _url});
  }

    return (
        <IonPage>
        <IonContent>
            <IonItem>
            <IonText onClick={()=>copy(id)}>Device ID: {id} <IonIcon icon={copyOutline}  /></IonText>
            </IonItem>
            <IonItem>
                <IonNote>Connect with our community for dispute resolutions and new features</IonNote>
            </IonItem>
            <div>
            <IonButton color="secondary" expand="full" onClick={()=>{openUri(settings.telegram)}}>Telegram</IonButton>
            </div>
            <div>
            <IonButton color="success" expand="full"  onClick={()=>{openUri(settings.whatsapp)}}>Whatsapp</IonButton>
            </div>
            <div>
            <IonButton color="warning" expand="full"  onClick={()=>{openUri(settings.livechat)}}>Live Chat</IonButton>
            </div>
            <div>
            <IonButton color="primary" expand="full"  onClick={(e)=>history.push('/support/feedback')}>Give us a feedback</IonButton>
            </div>
            </IonContent>
        </IonPage>
    )
}

export default Support