import { IonPage, IonItem, IonLabel, IonContent, IonNote, 
    IonText, IonList,  useIonViewWillEnter, withIonLifeCycle, 
    useIonLoading, useIonToast, IonAvatar, IonImg, IonSpinner, useIonViewWillLeave } from '@ionic/react'
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../state/GlobalState';
import { Storage } from '@ionic/storage';

const Billers:React.FC<any> = ({history,match}) => {
    const {apiUrl, setTitle} = useContext(GlobalContext);
    const [billers, setBillers] = useState<any>([]);
    const [present, dismiss] = useIonLoading();
    const [presentT1, dismissT1] = useIonToast();
    const [disableSpinner, setDisableSpinner] = useState<any>(true);
    useIonViewWillEnter(async () =>  {
        setTitle('Billers');
        setDisableSpinner(false);
        axios({
            url:apiUrl+'/getSVABillers',
            method: 'post',
            responseType: 'json',
            data: {
                category_id: match.params.categoryid
            }}).then((result:any) => {
                setBillers(result.data.billers.sort());
            }).catch((error:any)=>{
                presentT1({
                    buttons: [{ text: 'retry', handler: () => {history.reload()} }],
                    message:  error.message,
                    color:'danger'
                });
            }).finally(()=>{
                setDisableSpinner(true);
            });
    });
    
    useIonViewWillLeave(async() =>{
        setBillers([]);
      });
    const handleClick = async (biller:any) => { 
        const storage = new Storage();
        await storage.create();
        await storage.set('biller',biller);
        history.replace("/billers/"+biller.billerid);
    }
    return (
        <IonPage>
            <IonContent>
             <div style={{textAlign:'center'}}>
                 <IonSpinner hidden={disableSpinner} />
                 </div>
            <IonList>
                
            {
                 billers.map((biller :any)=> 
                 (
      <IonItem detail={true} onClick={()=>handleClick(biller)}>
        <IonLabel>
        <IonText>{biller.billername}</IonText><br/>
        <IonNote>{biller.narration}</IonNote>
        </IonLabel>
      </IonItem>
                 )) }
    </IonList>
                </IonContent>
        </IonPage>
    )
}

export default withIonLifeCycle(Billers)