import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent,
    useIonLoading, useIonToast, useIonViewWillEnter, withIonLifeCycle, IonSelect, IonSelectOption } from '@ionic/react'
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../state/GlobalState';
import app from '../firebase.config.js';
import { getAuth} from "firebase/auth";
import axios from 'axios';
import { Device } from '@capacitor/device';

const LinkBankAccount:React.FC<any> = ({history}) => {
   const [id, setID] = useState<any>('');
   const [device, setDevice] = useState<any>();
   const [present, dismiss] = useIonLoading();
   const [presentT1, dismissT1] = useIonToast();
   const {apiUrl, setTitle, idToken_} = useContext(GlobalContext);
   const [bank, setBank] = useState<any>();
   const [banks, setBanks] = useState<any>([]);
   const [accountNumber, setAccountNumber] = useState<any>();
   const [coin, setCoin] = useState<any>();
   const [coins, setCoins] = useState<any>([]);
   const [accountName, setAccountName] = useState<any>();
   const [disableTxt1, setDisableTxt1] = useState<any>(true);
   const [doBtn, setDoBtn] = useState<any>(true);
   const [btnText, setBtnText] = useState<any>('Validate');
   const [btnColor, setBtnColor] = useState<any>('warning');
   function dynamicSort(property: any)
 {
     var sortOrder = 1;
     if(property[0] === "-"){
         sortOrder = -1;
         property = property.substring(1);
     }
     return function (a:any,b:any) {
         var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
         return result * sortOrder;
     }
 }
   useIonViewWillEnter(async () =>  {
       setTitle('Link Bank Account');
       
    const _id = await Device.getId(); 
    const _device = await Device.getInfo();
    setDevice(_device);
    setID(_id.uuid);
       fetchCoins();
       fetchBanks();
   });
   
   const fetchCoins = async() =>{
    const _coins = await axios.get(apiUrl+'/getCoins');
    setCoins(_coins.data);
    setCoin(_coins.data[0]);
  }
  const fetchBanks = async() =>{
    const _banks = await axios.get(apiUrl+'/getBanks');
    setBanks(_banks.data.data.sort(dynamicSort("name")));
    setBank(_banks.data.data.sort(dynamicSort("name"))[0]);
  }
  const validateAcc = async() => {
       present({
            message: 'Please wait'
          });
    const result = await axios({
        url: apiUrl+'/validateAcc',
        method: 'post',
        responseType: 'json',
        data: {
          account_number: accountNumber,
          account_bank: bank.code
        }
        });
    if(result.status == 200){
        if(result.data.error){
            presentT1({
            buttons: [{ text: 'close', handler: () => dismissT1() }],
            message: result.data.msg,
            color: 'danger'
          });
        }else{
        setDisableTxt1(false);
        setDoBtn(false);
        setBtnText('Link');
        setBtnColor('success');
        setAccountName(result.data.data.meta.account_name);
        }
    }else{
        presentT1({
            buttons: [{ text: 'close', handler: () => dismissT1() }],
            message: result.statusText,
            color: 'danger'
          });
    }
    dismiss();
  }
  const sendLinkAccount  = async() => {
       present({
            message: 'Please wait'
          });
    const result = await axios({
        url: apiUrl+'/linkBankAccount',
        method: 'post',
        responseType: 'json',
        data: {
          id: id,
          device: device,
          account_number: accountNumber,
          account_bank: bank,
          coin: coin,
          account_name: accountName,
          idToken: idToken_,
        }
        });
    if(result.status == 200){
        presentT1({
            buttons: [{ text: 'close', handler: () => dismissT1() }],
            message: 'Account Linked',
            color: 'success'
          });
          setDoBtn(true);
          setBtnText('Validate');
          setBtnColor('warning');
          setDisableTxt1(true);
          setCoin(coins[0]);
          setBank(banks[0]);
          setAccountName('');
          setAccountNumber('');
    }else{
        presentT1({
            buttons: [{ text: 'close', handler: () => dismissT1() }],
            message: result.statusText,
            color: 'danger'
          });
    }
    dismiss();
  }
  const doBtnClick = async() => {
      if(doBtn){
          validateAcc();
      }else{
          sendLinkAccount()
      }
  }
  const navLinkedAcc = async() => {
    history.push('/categories')
  }
   return (
       <IonPage>
           <IonContent>
               <h3 style={{textAlign:'center'}}>Link Bank Account</h3>
               <IonItem>
                <IonLabel position="stacked">Bank</IonLabel>
                <IonSelect onIonChange={e => setBank(e.detail.value)} value={bank}>
              {
                    banks.map((bank :any)=> 
                    (
                    <IonSelectOption  value={bank}>{ bank.name}</IonSelectOption>
                    )
                    )
                }
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Account Number</IonLabel>
                <IonInput value={accountNumber} onIonChange={(e)=>setAccountNumber(e.detail.value) }/>
            </IonItem>
            
            <IonItem hidden={disableTxt1}>
            <IonLabel position="stacked">Account Number</IonLabel>
            <IonInput value={accountName} readonly />
            
            </IonItem>
            <IonItem hidden={disableTxt1}>
                <IonLabel position="stacked">Coin</IonLabel>
                <IonSelect  onIonChange={e => setCoin(e.detail.value)} value={coin} >
              {
                    coins.map((coin :any)=> 
                    (
                    <IonSelectOption  value={coin}>{coin.name}</IonSelectOption>
                    )
                    )
                }</IonSelect>
            </IonItem>
            <IonButton expand="full" color={btnColor} onClick={()=>doBtnClick()}>{btnText}</IonButton>
               </IonContent>
               <br/>
               <hr/>
               <br/>
            <IonButton expand="full" color="medium" onClick={()=>navLinkedAcc()}>View Linked Accounts</IonButton>
           </IonPage>
   )
}

export default withIonLifeCycle(LinkBankAccount)


