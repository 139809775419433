import { IonPage, IonItem, IonLabel, IonInput, IonButton, IonContent, IonNote, IonIcon, IonText, IonSelect, IonSelectOption, IonCheckbox, IonImg, IonChip, IonCol, IonGrid, IonRow, useIonLoading, useIonToast, useIonViewWillEnter } from '@ionic/react'
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../state/GlobalState';
import { matchPath} from 'react-router';

const RefundOrder:React.FC<any> = ({history,match}) => {
const {apiUrl, setTitle} = useContext(GlobalContext);
const [order, setOrder] = useState<any>({coin:'',currency:''});
const [address, setAddress] = useState<any>('');
const [present, dismiss] = useIonToast();
const [present1, dismiss1] = useIonLoading();
const loadOrder = async() => {
    const match2 =  matchPath(history.location.pathname,{
    path:match.path,
    exact: match.isExact,
    strict: false
});
const params : any = match2?.params;
    const _order = await axios({
        url:apiUrl+'/getOrder',
        method: 'post',
        data: { 
            ref: params.orderid
        },
        responseType: 'json'});
    setOrder(_order.data);
 }
const refund = async() => {
    present1({
        message: 'Please wait'
      });
    const response = await axios({
        url:apiUrl+'/refund',
        method: 'post',
        data: { 
            ref: order.ref,
            address: address
        },
        responseType: 'json'});
        if(response.data.error){
            present({
                buttons: [{ text: 'close', handler: () => dismiss() }],
                color:'danger',
                message: response.data.msg
              });
        }else{
            present({
                buttons: [{ text: 'close', handler: () => dismiss() }],
                message: "Refund request sent"
              });
              history.replace('/orders');
        }
        
        dismiss1();
}
useIonViewWillEnter(async () =>  {
    setTitle('Refund Order')
    await loadOrder();
})
    return (
        <IonPage><IonContent >
              <IonItem>
              <IonText  style={{margin:'auto', fontWeight:'200'}} 
        class="ion-text-center">Refund request for {order.ref}</IonText>
              </IonItem><div>
              <IonLabel position="stacked">Your {order.coin.toUpperCase()} Address</IonLabel>
              <IonInput onIonChange={e => setAddress(e.detail.value)} value={address}>
                  </IonInput></div>
              <IonButton expand="full" color="success" onClick={(e) => refund()}>Refund</IonButton>    
</IonContent>
</IonPage>
    )
}

export default RefundOrder