
import React, { useContext, useState } from 'react';
import {
    useIonViewWillEnter, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonCardContent, IonItem, IonImg, IonText, IonIcon, IonButton, useIonActionSheet, useIonLoading, useIonToast, withIonLifeCycle, IonPage, IonContent, IonChip
} from "@ionic/react";
import axios from "axios";
import { copyOutline } from "ionicons/icons";
import { matchPath } from "react-router";
import { GlobalContext } from "../state/GlobalState";
import { Clipboard } from '@capacitor/clipboard';
import { Storage } from '@ionic/storage';

const ViewLinkedAccount: React.FC<any> = ({ history, match }) => {

    const { apiUrl, timeInterval } = useContext(GlobalContext);
    const [linkedAcc, setLinkedAcc] = useState<any>({});
    const [present, dismiss] = useIonLoading();
    const [presentA1, dismissA1] = useIonActionSheet();
    const [presentT1, dismissT1] = useIonToast();
    const [idToken_, setIDToken] = useState<any>();
    const [coinPrice, setCoinPrice] = useState<any>();
    useIonViewWillEnter(async () => {
        const storage = new Storage();
        await storage.create();
        const idtoken__ = await storage.get('idToken_');
        setIDToken(idtoken__);
        const match2 = matchPath(history.location.pathname, {
            path: match.path,
            exact: match.isExact,
            strict: false
        });
        const params: any = match2?.params;
        const _linkedAccount = await axios({
            url: apiUrl + '/getLinkedAccount',
            method: 'post',
            data: {
                id: params.accountid,
                idToken: idtoken__
            },
            responseType: 'json'
        });
        setLinkedAcc(_linkedAccount.data);
        fetchCoinPrices(_linkedAccount.data.coin.pair)
        setInterval(() => {
            fetchCoinPrices(_linkedAccount.data.coin.pair)
        }, 10000);
    })
    const copyAddr = async (val: any) => {
        await Clipboard.write({
            string: val
        });
        presentT1({
            buttons: [{ text: 'close', handler: () => dismissT1() }],
            message: "Address copied"
        });
    }
    const confirmDelete = async (id: any) => {
        presentA1([
            {
                text: 'Yes, Delete', handler: async () => {
                    //handle deletion\
                    deleteLinkedAcc(id);
                }
            },
            { text: 'No, Cancel', handler: dismissA1 }
        ],
            'Are you sure you wish to delete this linked bank account'
        );
    }

    const deleteLinkedAcc = async (id: any) => {
        present({
            message: 'Please wait'
        })
        const trx = await axios({
            url: apiUrl + '/deleteLinkedAccount',
            method: 'post',
            responseType: 'json',
            data: {
                id: id,
                idToken: idToken_
            }
        });
        dismiss();
        history.push('/categories');
    }
    const fetchCoinPrices = async (pair: any) => {
        const _coinsPrices = await axios.get(apiUrl + '/getCoinsPrices');
        _coinsPrices.data.forEach((_coinPrice: any) => {
            if (_coinPrice.pair == pair) {
                setCoinPrice(_coinPrice);
            }
        });
    }
    return (
        <IonPage><IonContent style={{ padding: '1em' }}>
            <IonCard color="dark" style={{ margin: '1rem' }} class="ion-text-center">
                <IonCardHeader>
                    <IonCardSubtitle>{linkedAcc?.accountNumber}</IonCardSubtitle>
                    <IonCardTitle>{linkedAcc?.bankName} ~ {linkedAcc.coin?.name}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonChip color='light'>₦{coinPrice?.price}</IonChip>
                    <IonItem> <IonImg src={'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' + linkedAcc?.address} style={{ width: '200px', height: '200px', margin: 'auto' }} /></IonItem>
                    <IonItem >
                        <IonText style={{ margin: 'auto', fontWeight: '200' }} onClick={() => copyAddr(linkedAcc?.address)}
                            class="ion-text-center">{linkedAcc?.address}</IonText><IonIcon icon={copyOutline} onClick={() => copyAddr(linkedAcc.address)} />
                    </IonItem>
                    <IonButton color="danger" expand="full" onClick={() => confirmDelete(linkedAcc.ref)}>Delete</IonButton>
                </IonCardContent>
            </IonCard></IonContent></IonPage>
    )
}

export default ViewLinkedAccount;
